import CloseIcon from 'ionicons/dist/svg/close-outline.svg?component'
import OpenIcon from 'ionicons/dist/svg/open-outline.svg?component'

import type { IContent } from './ModalProvider'

import './Modal.css'

/**
 * Modal component
 */
const Modal: preact.FunctionComponent<{
  content: IContent|null,
  onClose: () => void,
}> = ({
  content,
  onClose,
}) =>
  content &&
    <aside className="iyp-modal">
      {/** Dialog */}
      <div className="iyp-modal__dialog">
        {/** Header */}
        <div className="iyp-modal__header">
          {/** Title */}
          <h2 className="iyp-modal__title">
            { content.header }
          </h2>
          <div className="iyp-modal-buttons">
            {/** Close button */}
            <button
              className="iyp-modal-button iyp-modal-button--close"
              title="Close"
              onClick={() => onClose()}
            >
              <CloseIcon />
            </button>
            {/** URL button */}
            {content.url &&
              <a
                className="iyp-modal-button iyp-modal-button--url"
                href={content.url}
                title="Open in new tab"
                rel="next"
                referrerPolicy="origin-when-cross-origin"
                target="_parent"
              >
                <OpenIcon />
              </a>
            }
          </div>
        </div>
        {/** Body */}
        <div className="iyp-modal__body iyp-scrollbar">
          { content.body }
        </div>
      </div>
      {/** Backdrop */}
      <div
        className="iyp-modal__backdrop"
        onClick={() => onClose()}
      ></div>
    </aside>

export default Modal

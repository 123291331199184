/**
 * Loading indicator
 * @see https://getuikit.com/docs/spinner
 */

import './Spinner.css'

const Spnner: preact.FunctionComponent<{
  size?: number
  overlay?: boolean
}> = ({
  size = 3,
  overlay = false
}) =>
  <div className={`iyp-spinner ${overlay ? 'iyp-spinner--overlay' : ''}`}>
    <svg
      width={size * 30}
      height={size * 30}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        stroke="#000"
        cx={15}
        cy={15}
        r={14}
        style={{strokeWidth: 1 / size}}
      ></circle>
    </svg>
  </div>

export default Spnner

import Button from '../Button/Button'

import './Error.css'

/**
 * Error component
 */
const Error: preact.FunctionComponent<{
  /** Error instance */
  error: Error,
  /** Inside (modal) component */
  inline?: boolean,
  /** Retry callback */
  retryFn?: () => void,
}> = ({
  error,
  inline = false,
  retryFn = undefined,
}) => {
  return (
    <div className={`iyp-error ${inline ? 'iyp-error--inline' : ''}`}>
      <h1 className="iyp-h1">
        {'Error'}
      </h1>
      <p>
        {error.message}
      </p>
      {retryFn &&
        <Button
          onClick={retryFn}
          text={'Retry'}
        />
      }
    </div>
  )
}

export default Error

/**
 * Marker icon
 */
import {
  Icon,
  DivIcon,
  Point,
} from 'leaflet'
import type { DivIconOptions } from 'leaflet'

import type { IVenueListItem } from '../../models/venue.interfaces'

import shadowImgUrl from '../../images/map-marker.shadow.svg'
import './MarkerIcon.css'

/**
 * Generic marker icon
 */
export default class MarkerIcon extends DivIcon {
  /**
   * Constructor
   */
  public constructor({
    venue,
    current,
    ...options
  }: {
    venue: IVenueListItem
    current?: boolean
    options?: DivIconOptions
  }) {
    super({
      html: `
        <svg
          width="40"
          height="60"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use
            class="iyp-marker-icon iyp-marker-icon--${venue.attribs.length ? 'premium' : 'regular'} ${current ? 'iyp-marker-icon--current' : ''}"
            xlink:href="#iyp-marker-icon-point"
          />
        </svg>
      `,
      className: 'iyp-marker',
      shadowUrl: shadowImgUrl,
      iconSize: new Point(40, 60),
      shadowSize: [50, 45],
      iconAnchor: [20, 60],
      shadowAnchor: [0, 45],
      popupAnchor: [0, -60],
      ...options,
    })
  }

  /**
   * Hack to use shadow on L.DivIcon
   * @inheritdoc
   */
  public createShadow(oldIcon?: HTMLElement): HTMLImageElement {
    return Icon.prototype.createShadow.call(this, oldIcon) as HTMLImageElement
  }
}

import ApiError from './Error/ApiError'
import ConnectError from './Error/ConnectError'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiFetcher = (path: string, requestInit?: RequestInit) => Promise<any>

/**
 * SWR Fetcher factory
 */
export function createFetcher (baseUrl: string): ApiFetcher {
  return async (path: string, requestInit?: RequestInit) => {

    const url = `${baseUrl}/${path}`
    const request = new Request(url, requestInit)

    let response: Response

    try {
      response = await fetch(request)
    } catch (typeError) {
      throw new ConnectError(request, { cause: typeError as TypeError })
    }

    // Handle API errors
    if (!response.ok) {
      throw new ApiError(request, response)
    }

    return await response.json()
  }
}

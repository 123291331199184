import { memo } from 'preact/compat'

import type { ISocialLink } from '../../../models/venue.interfaces'

import '../../../icons.css'
import './SocialLinks.css'

const SocialLinks: preact.FunctionComponent<{
  value: ISocialLink[]
}> = ({
  value
}) =>
  <div className="iyp-venue-extra">
    <h3 className="iyp-venue-extra__heading">
      {'Social links'}
    </h3>
    <ul className="iyp-venue-extra__content iyp-list-flat">
      {value.map((social, index) =>
        <li key={index}>
          <a
            className={`iyp-social-link i-${social.type}`}
            href={social.url}
            target="external"
            title={social.type}
          ></a>
        </li>
      )}
    </ul>
  </div>

export default memo(SocialLinks)

import { memo } from 'preact/compat'

const Website: preact.FunctionComponent<{
  value: string
}> = ({
  value
}) =>
  <div className="iyp-venue-extra">
    <h3 className="iyp-venue-extra__heading">
      {'Website'}
    </h3>
    <p className="iyp-venue-extra__content">
      <a
        href={value}
        target="external"
      >
        {value.replace(/^https?:\/\/(.*)\/$/, '$1')}
      </a>
    </p>
  </div>

export default memo(Website)

import {
  Marker as LeafletMarker,
  Tooltip,
} from 'react-leaflet'

import type { LeafletMouseEvent } from 'leaflet'

import type { IVenueListItem } from '../../models/venue.interfaces'
import { useModal } from '../Modal/ModalProvider'
import MarkerIcon from '../MarkerIcon/MarkerIcon'
import Venue from '../Venue/VenueDetails'

/**
 * Venue Marker
 * @see https://github.com/PaulLeCam/react-leaflet/issues/771
 */
const Marker: preact.FunctionComponent<{
  /** Item */
  venueListItem: IVenueListItem
  /** Set as current */
  current?: boolean
}> = ({
  venueListItem,
  current = false,
}) => {
  const setModalContent = useModal()

  const handleMarkerClick = (event: LeafletMouseEvent): void =>
    setModalContent({
      header: venueListItem.name,
      body: <Venue venueListItem={venueListItem} />,
      url: venueListItem.url,
    })

  return (
    <LeafletMarker
      icon={new MarkerIcon({ venue: venueListItem, current })}
      position={venueListItem.location}
      zIndexOffset={current && 1e3}
      eventHandlers={{
        click: handleMarkerClick,
      }}
    >
      <Tooltip>
        {venueListItem.name}
      </Tooltip>
    </LeafletMarker>
  )
}

export default Marker

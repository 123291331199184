import { useLocation } from 'wouter-preact'

import ErrorPage from '../Error/ErrorPage'

/**
 * Not found
 */
const NotFound: preact.FunctionComponent<{
  params: { path: string }
}> = () => {
  const [ location ] = useLocation()

  // Trim slash from beginning
  const path = location.replace(/^\/(.*)/, '$1')

  return (
    <ErrorPage
      title={'404: Not Found'}
      description={`The '${path}' route is gone :(`}
    />
  )
}

export default NotFound

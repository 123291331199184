import PrevIcon from 'ionicons/dist/svg/chevron-back-outline.svg?component'
import NextIcon from 'ionicons/dist/svg/chevron-forward-outline.svg?component'

import './VenueGalleryNavButton.css'

const GalleryNavButton: preact.FunctionComponent<{
  step: number,
  onClick: (direction: number) => void,
}> = ({
  step,
  onClick,
}) => {
  return (
    <button
      className="iyp-venue-galery-nav__button"
      type="button"
      onClick={() => onClick(step)}
    >
      {step < 0
        ? <PrevIcon />
        : <NextIcon />
      }
    </button>
  )
}

export default GalleryNavButton

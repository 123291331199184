import AbstractError from './AbstractError'

/**
 * Simple app error
 */
export default class AppError extends AbstractError {
  /**
   * @inheritdoc
   */
  constructor(message: string) {
    super(message)

    Object.setPrototypeOf(this, AppError.prototype)
  }
}

AppError.prototype.name = 'AppError'

import type { TSocialLinkType } from './venue.interfaces'

/**
 * Venue attributes
 */
export enum ATTRIB {
  FEATURED     = 'featured',
  EDITORS_PICK = 'editor-pick',
  CHAPTER_PICK = 'chapter-pick',
}

export const premiumAttribs = [
  ATTRIB.FEATURED,
  ATTRIB.EDITORS_PICK,
  ATTRIB.CHAPTER_PICK,
]

/**
 * Social links
 */
export enum SOCIAL_LINK_TYPE {
  TWITTER    = 'twitter',
  FACEBOOK   = 'facebook',
  YOUTUBE    = 'youtube',
  FLICKR     = 'flickr',
  FOURSQUARE = 'foursquare',
  INSTAGRAM  = 'instagram',
  PINTEREST  = 'pinterest',
}

export const socialLinkTypes: TSocialLinkType[] = [
  SOCIAL_LINK_TYPE.TWITTER,
  SOCIAL_LINK_TYPE.FACEBOOK,
  SOCIAL_LINK_TYPE.YOUTUBE,
  SOCIAL_LINK_TYPE.FLICKR,
  SOCIAL_LINK_TYPE.FOURSQUARE,
  SOCIAL_LINK_TYPE.INSTAGRAM,
  SOCIAL_LINK_TYPE.PINTEREST,
]

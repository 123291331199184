import { useMemo } from 'preact/hooks'
import { LatLngBounds, Point } from 'leaflet'
import { useMap } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'

import type { IVenueListItem } from '../../models/venue.interfaces'
import Marker from './Marker'
import { iconCreateFunction } from '../MarkerIcon/MarkerClusterIcon'

import 'react-leaflet-markercluster/dist/styles.min.css'

/**
 * Map child is required when centering map to venues (map container params are immutable)
 * see https://react-leaflet.js.org/docs/example-events
 */
const MarkersGroup: preact.FunctionComponent<{
  /** List of venues */
  venuesList: IVenueListItem[]
  /** Marker used to set center and extend bounds */
  current?: IVenueListItem
}> = ({
  venuesList,
  current,
}) => {
  const map = useMap()

  // Compute bounds
  const bounds: LatLngBounds = useMemo(
    () => new LatLngBounds(venuesList.map(venueListItem => venueListItem.location)),
    [venuesList]
  )

  if (current) {
    bounds.extend(current.location)
  }

  if (!bounds.isValid()) {
    return null
  }

  // Resolve center
  const center = current
    ? current.location
    : bounds.getCenter()

  // Resolve zoom
  const container = map.getContainer()
  const bcr = container.getBoundingClientRect()
  const padding = new Point(bcr.width / 2, bcr.height / 2)

  const zoom = map.getBoundsZoom(bounds, false, padding)

  // Set map view
  map.setView(center, zoom)

  return (
    <MarkerClusterGroup
      showCoverageOnHover={false}
      iconCreateFunction={iconCreateFunction}
    >
      {venuesList.map(venuesListItem =>
        <Marker
          key={venuesListItem.id}
          venueListItem={venuesListItem}
        />
      )}
    </MarkerClusterGroup>
  )
}

export default MarkersGroup

import { memo } from 'preact/compat'

/**
 * Opening hours as a pretty formatted string
 */
const OpeningHoursText: preact.FunctionComponent<{
  value: string
}> = ({
  value
}) => {
  return (
    <div className="iyp-venue-extra">
      <h3 className="iyp-venue-extra__heading">
        {'Open'}
      </h3>
      <p className="iyp-venue-extra__content iyp-opening-hours-pretty">
        {value}
      </p>
    </div>
  )
}

export default memo(OpeningHoursText)

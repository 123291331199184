import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import type { TApiEventDetailsResponse } from './responses/event'
import TransferError from '../Utils/Error/TransferError'
import { createRetry } from './utils'
import transform from './transformers/event'

export default function useEvent(
  eventId: number,
  showFeaturedOnly = false,
  configuration?: SWRConfiguration<TApiEventDetailsResponse>
) {
  const {
    data: eventPayload,
    error,
    mutate
  } = useSWR<TApiEventDetailsResponse, TransferError>(`event/${eventId}`, configuration)

  return {
    event: eventPayload ? transform(eventPayload, showFeaturedOnly) : undefined,
    error,
    mutate,
    retry: createRetry(mutate),
  }
}

/**
 * Embedded modes
 */
export enum EMBEDDED_MODE {
  /** Standalone */
  STANDALONE = 'standalone',
  /** IYP widget */
  IYP_WIDGET = 'iyp-widget',
  /** 3p widget */
  THIRD_PARTY_WIDGET = 'third-party-widget',
}

/**
 * Get embedded mode
 * Expect to be embedded in iyp pages using iframe with loose referrerpolicy
 *
 * @example
 * const showBranding = getEmbeddedMode() === EMBEDDED_MODE.THIRD_PARTY
 */
export function getEmbeddedMode(iypOrigin: string): EMBEDDED_MODE {
  // Not embedded
  if (window.parent === window) {
    return EMBEDDED_MODE.STANDALONE
  }

  // Prevented to access referrer
  if (!document.referrer) {
    return EMBEDDED_MODE.THIRD_PARTY_WIDGET
  }

  const referrerUrl = new URL(document.referrer)

  // Trusted
  if (referrerUrl.origin === iypOrigin) {
    return EMBEDDED_MODE.IYP_WIDGET
  }

  return EMBEDDED_MODE.THIRD_PARTY_WIDGET
}

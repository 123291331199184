import useEvent from '../../data/use-event'
import { hasShowFeaturedOnly } from '../../Utils/url'

import Map from '../../Components/Map/Map'
import Spinner from '../../Components/Spinner/Spinner'
import Error from '../../Components/Error/Error'
import PageHeading from '../../Components/PageHeading/PageHeading'

/**
 * Event page
 */
const EventPage: preact.FunctionComponent<{
  params: {
    eventId: string
  }
}> = ({
  params: {
    eventId
  }
}) => {
  const { event, error: transferError, retry } = useEvent(
    Number.parseInt(eventId, 10),
    hasShowFeaturedOnly(window.location.search)
  )

  return (
    <section className="iyp-page iyp-page--map">
      <PageHeading
        label="Event"
        features={['Associated venues (blue)']}
      />

      {transferError
        ? <Error error={transferError} retryFn={retry} />
        : event
          ? <Map venuesList={event.venues} />
          : <Spinner />
      }
    </section>
  )
}

export default EventPage

import type { TErrorOptions } from './AbstractError'
import TransferError from './TransferError'

/**
 * TypeError: Failed to fetch
 */
export default class ConnectError extends TransferError {
  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    options?: TErrorOptions,
  ) {
    super(request, 'No connection', options)

    Object.setPrototypeOf(this, ConnectError.prototype)
  }
}

ConnectError.prototype.name = 'ConnectError'

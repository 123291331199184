import ShareIcon from 'ionicons/dist/svg/share-social-outline.svg?component'

import type { IVenueListItem } from '../../models/venue.interfaces'

import Button from '../Button/Button'

/**
 * Venue share button
 * @link https://codepen.io/ayoisaiah/pen/YbNazJ
 */
const VenueShareButton: preact.FunctionComponent<{
  venue: IVenueListItem,
}> = ({
  venue,
}) => {
  const handleButtonClick = () => {
    navigator.share({
      title: venue.name,
      text: `In Your Pocket: ${venue.name}`,
      url: venue.url,
    })
    // InvalidStateError: Failed to execute 'share' on 'Navigator': A earlier share had not yet completed.
    .catch(error => {}) // eslint-disable-line @typescript-eslint/no-empty-function
  }

  // Sorry, no inline fallback - modal is busy
  if (!navigator.share) {
    return null
  }

  return (
    <Button
      text={'Share'}
      icon={ShareIcon}
      onClick={handleButtonClick}
    />
  )
}

/** Web Share API support */
export const canShare = 'share' in navigator

export default VenueShareButton

import type { ControlPosition, ControlOptions } from 'leaflet'

import logoUrl from '../../images/logo-essentials.svg'
import logoWhiteUrl from '../../images/logo-essentials--white.svg'

import './BrandingControl.css'

/** Classes used by Leaflet to position controls */
const POSITION_CLASSNAME: Record<ControlPosition, string> = {
  bottomleft:  'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft:     'leaflet-top leaflet-left',
  topright:    'leaflet-top leaflet-right',
}

/**
 * Branding react control
 * @see https://react-leaflet.js.org/docs/example-react-control/
 */
const BrandingControl: preact.FunctionComponent<
  ControlOptions
> = ({
  position = 'topright',
}) =>
  <div className={POSITION_CLASSNAME[position] ?? POSITION_CLASSNAME.topright}>
    <div className="leaflet-control leaflet-bar iyp-branding">
      <a
        className="iyp-branding__anchor"
        href="https://www.inyourpocket.com"
        title="Open inyourpocket.com web in new tab"
        rel="next"
        referrerpolicy="origin-when-cross-origin"
        target="_parent"
      >
        <picture>
          <source
            className="iyp-branding__image"
            media="(prefers-color-scheme: dark)"
            srcset={logoWhiteUrl}
          />
          <img
            className="iyp-branding__image"
            src={logoUrl}
            width={64}
            height={57}
            alt="inyourpocket.com"
          />
        </picture>
      </a>
    </div>
  </div>

export default BrandingControl

import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import type { TVenueDetails } from './models/venue'
import TransferError from '../Utils/Error/TransferError'
import { createRetry } from './utils'
import transform from './transformers/venue'

export default function useVenue(
  venueId: number,
  showFeaturedOnly = false,
  configuration?: SWRConfiguration<TVenueDetails>
) {
  const {
    data: venuePayload,
    error,
    mutate,
  } = useSWR<TVenueDetails, TransferError>(`venue/${venueId}`, configuration)

  return {
    venue: venuePayload ? transform(venuePayload, showFeaturedOnly) : undefined,
    error,
    mutate,
    retry: createRetry(mutate),
  }
}

import useVenueList from '../../data/use-venue-list'
import { hasShowFeaturedOnly } from '../../Utils/url'

import Map from '../../Components/Map/Map'
import Spinner from '../../Components/Spinner/Spinner'
import Error from '../../Components/Error/Error'
import PageHeading from '../../Components/PageHeading/PageHeading'

/**
 * Category
 */
const CategoryPage: preact.FunctionComponent<{
  params: {
    categoryId: string
  }
}> = ({
  params: {
    categoryId
  }
}) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const districtIdsString = urlSearchParams.get('districts')

  const { venues, error: transferError, retry } = useVenueList({
    categoryId: parseIdArray(categoryId),
    districtId: districtIdsString ? parseIdArray(districtIdsString) : undefined,
    featuredOnly: hasShowFeaturedOnly(window.location.search),
  })

  return (
    <section className="iyp-page iyp-page--map">
      <PageHeading
        label="Category"
        features={['Venues for current category']}
      />

      {transferError
        ? <Error error={transferError} retryFn={retry} />
        : venues
          ? <Map venuesList={venues} />
          : <Spinner />
      }

    </section>
  )
}

export default CategoryPage

/**
 * Parse comma separated IDs (numbers)
 */
function parseIdArray(idsString: string): number[] {
  return idsString
    .split(',')
    .map(idString => Number.parseInt(idString, 10))
}

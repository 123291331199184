import TransferError from './TransferError'

/**
 * API/ HTTP error
 */
export default class ApiError extends TransferError {
  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    public response: Response,
  ) {
    super(request, 'An error occured while fetching the data')

    Object.setPrototypeOf(this, ApiError.prototype)
  }
}

ApiError.prototype.name = 'ApiError'

import { memo } from 'preact/compat'

import type { IOpeningHours } from '../../../models/venue.interfaces'

import './OpeningHours.css'

/**
 * Day names dictionary
 * Treat key as constant
 */
const daynames: Map<string, string> = new Map([
  ['mo', 'Mon'],
  ['tu', 'Tue'],
  ['we', 'Wed'],
  ['th', 'Thu'],
  ['fr', 'Fri'],
  ['sa', 'Sat'],
  ['su', 'Sun'],
])

const OpeningHours: preact.FunctionComponent<{
  value: IOpeningHours
}> = ({
  value
}) => {
  // Assume first item is a global rule ('daily') and rest are sorted days
  // TODO: Clarify with API dev
  const [
    daily,
    ...weekdays
  ] = value.days

  // Group weekdays by hours while preserving order
  const byHours: Map<string, string[]> = weekdays
    .filter(openingHour => openingHour.hour)
    .reduce((map, {day, hour}) =>
      map.set(
        hour,
        (map.get(hour) || []).concat(day)
      ),
      new Map()
    )

  return (
    <div className="iyp-venue-extra">
      <h3 className="iyp-venue-extra__heading">
        {'Open'}
      </h3>
      <ul className="iyp-venue-extra__content iyp-list-flat iyp-opening-hours">
        <li>
          {`Open ${daily.hour}`}.
        </li>
        {Array.from(byHours)
          .map(([hour, days], index) =>
            <li key={index}>
              {days
                .map(day => daynames.get(day))
                .join(', ')
              }: {hour}
              {index !== byHours.size -1 ? ', ' : '.'}
            </li>
          )
        }
      </ul>
    </div>
  )
}

export default memo(OpeningHours)

import { useState, useRef, useEffect } from 'preact/compat'

import type { TGalleryImage } from '../../models/venue.interfaces'
import GalleryNavButton from './VenueGalleryNavButton'

import './VenueGallery.css'

/**
 * Venue gallery
 */
const VenueGallery: preact.FunctionComponent<{
  photos: TGalleryImage[]
}> = ({
  photos,
}) => {
  const list = useRef<HTMLUListElement>()
  const items = useRef<HTMLLIElement[]>([])

  const allItemsVisible = useIsVisible(list)

  /**
   * Handle nav button click
   * Note: Exclamation marks are to force defined ref objects assuming that event is fired after render
   * @param {number} step - Positive/ negative step
   */
  const handleNavClick = (step: number): void => {
    if (!list.current) {
      return
    }

    const count = items.current.length

    const currentItemIndex = step > 0 && list.current.scrollLeft === list.current.scrollWidth - list.current.offsetWidth
      // End of list
      ? items.current.length - 1
      // First item from the list that has left side >= list scroll position from left
      : items.current.findIndex(item => item.offsetLeft >= list.current!.scrollLeft)

    // Resolve index of next/ previous item
    const newIndex = (currentItemIndex + step + count) % count
    const item = items.current[newIndex]

    item.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      // Must match items' scroll-snap-align inline value (none|start|end|center)
      inline: 'start',
    })
  }

  return (
    <div className="iyp-venue-gallery iyp-margin--bottom">
      {/** Items */}
      <ul
        className="iyp-venue-gallery__items iyp-scrollbar"
        ref={element => element && (list.current = element)}
      >
        {photos.map(({description, sizes}, index) =>
          <li
            key={index}
            ref={element => element && (items.current[index] = element)}
            className="iyp-venue-gallery__item"
          >
            <img
              key={index}
              className="iyp-venue-gallery__image"
              src={sizes.small.url}
              width={sizes.small.width}
              height={sizes.small.height}
              srcSet={`
                ${encodeURI(sizes.small.url)} ${sizes.small.width}w,
                ${encodeURI(sizes.xSmall.url)} ${sizes.xSmall.width}w
              `}
              sizes={`(max-width: 768px) ${sizes.xSmall.width}px`}
              loading="lazy"
              alt={description}
            />
          </li>
        )}
      </ul>

      {/** Navigation */}
      <ul
        className="iyp-venue-gallery__navigation"
        hidden={allItemsVisible}
      >
        <li className="iyp-venue-gallery-nav iyp-venue-gallery-nav--prev">
          <GalleryNavButton
            step={-1}
            onClick={handleNavClick}
          />
        </li>
        <li className="iyp-venue-gallery-nav iyp-venue-gallery-nav--next">
          <GalleryNavButton
            step={1}
            onClick={handleNavClick}
          />
        </li>
      </ul>
    </div>
  )
}

/**
 * Check if element is completely visible within container
 * Note: consider debuounce with https://gomakethings.com/debouncing-events-with-requestanimationframe-for-better-performance/
 */
function useIsVisible(ref: preact.RefObject<HTMLElement|undefined>): boolean | undefined {
  const [ isVisible, setIsVisible ] = useState<boolean|undefined>(undefined)

  useEffect(() => {
    // N/A
    if ('ResizeObserver' in window === false) return

    const resizeObserver = new ResizeObserver(([entry]) => {
      const element = entry.target as HTMLElement

      setIsVisible(
        element.offsetWidth === element.scrollWidth
      )
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => resizeObserver.disconnect()
  }, [ref])

  return isVisible
}

export default VenueGallery

import type { TErrorOptions } from './AbstractError'
import AbstractError from './AbstractError'

/**
 * Based on Guzzle Exceptions
 * @see https://docs.guzzlephp.org/en/stable/quickstart.html#exceptions
 */
export default abstract class TransferError extends AbstractError {
  /**
   * @inheritdoc
   */
  constructor(
    public request: Request,
    message?: string,
    options?: TErrorOptions,
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(message, options)

    Object.setPrototypeOf(this, TransferError.prototype)
  }
}

TransferError.prototype.name = 'TransferError'

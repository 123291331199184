/**
 * Marker cluster icon
 */
import {
  Icon,
  DivIcon,
  Point,
  MarkerCluster,
} from 'leaflet'

import shadowImgUrl from '../../images/map-cluster.shadow.svg'
import './MarkerIcon.css'

/**
 * As default but with custom icon size
 */
export default class MarkerClustererIcon extends DivIcon {
  /**
   * Constructor
   */
  public constructor(markerCluster: MarkerCluster) {
    const childCount = markerCluster.getChildCount()
    const childCountDecimals = childCount.toFixed(0).length

    super({
      html: `
        <svg
          width="40"
          height="60"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use
            class="iyp-marker-icon"
            xlink:href="#iyp-marker-icon-cluster"
          />
        </svg>
        <div class="iyp-marker__count iyp-marker__count-${childCountDecimals}d">${childCount}</div>
      `,
      className: 'iyp-marker',
      shadowUrl: shadowImgUrl,
      // Same as for marker
      iconSize: new Point(40, 60),
      shadowSize: [50, 45],
      iconAnchor: [20, 60],
      shadowAnchor: [0, 45],
      popupAnchor: [0, -60],
    })
  }

  /**
   * @inheritdoc
   */
  public createShadow(oldIcon: HTMLElement): HTMLImageElement {
    return Icon.prototype.createShadow.call(this, oldIcon) as HTMLImageElement
  }
}

/**
 * Marker cluster iconCreateFunction
 */
export function iconCreateFunction(markerCluster: MarkerCluster): MarkerClustererIcon {
  return new MarkerClustererIcon(markerCluster)
}

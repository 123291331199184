import useVenueList from '../../data/use-venue-list'
import Map from '../../Components/Map/Map'
import Spinner from '../../Components/Spinner/Spinner'
import Error from '../../Components/Error/Error'
import PageHeading from '../../Components/PageHeading/PageHeading'

/**
 * Featured article page
 */
const FeaturedPage: preact.FunctionComponent<{
  params: {
    featureId: string
  }
}> = ({
  params: {
    featureId
  }
}) => {
  const { venues, error: transferError, retry } = useVenueList({
    featureId: Number.parseInt(featureId, 10),
    featuredOnly: false,
  }, true)

  return (
    <section className="iyp-page iyp-page--map">
      <PageHeading
        label="Featured article"
        features={['Associated venues (blue)']}
      />

      {transferError
        ? <Error error={transferError} retryFn={retry} />
        : venues
          ? <Map venuesList={venues} />
          : <Spinner />
      }
    </section>
  )
}

export default FeaturedPage

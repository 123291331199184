import './Button.css'

/**
 * Button
 */
const Button: preact.FunctionComponent<{
  text: string,
  icon?: preact.FunctionComponent<JSX.SVGAttributes<SVGElement> & { title?: string }>,
  value?: string,
  className?: string,
  disabled?: boolean,
  onClick: (event: preact.JSX.TargetedMouseEvent<HTMLButtonElement>) => void,
}> = ({
  text,
  icon: Icon = undefined,
  value = undefined,
  className = undefined,
  disabled = false,
  children = undefined,
  onClick,
}) =>
  <button
    className={`iyp-button ${className ?? ''}`}
    type="button"
    value={value}
    disabled={disabled}
    onClick={onClick}
  >
    {Icon &&
      <Icon className="iyp-button__icon ionicon iyp-icon iyp-icon--before iyp-color--purple" />
    }
    <span className="iyp-button__text">
      {text}
    </span>
    {children}
  </button>

export default Button

import type {
  TVenue,
  TValidVenue,
  TVenueItem,
  TValidVenueItem,
} from '../../models/venue'

import { ATTRIB } from '../../../models/venue.constants'

/**
 * Create venue URL in IYP page
 * https://www.inyourpocket.com/151328v or http://iyp.me/151328v
 */
export function getUrl(venueItem: TVenue | TVenueItem): string {
  return `https://www.inyourpocket.com/${venueItem.venue_id}v`
}

/**
 * Convert venue attribs
 * Note: API objects don't have featured flag, however it makes sense to set it manually
 *       when using dedicated API endpoint
 */
export function getAttribs(venueItem: TVenue | TVenueItem, forceFeatured = false): ATTRIB[] {
  const attribs: ATTRIB[] = []

  if (venueItem.isEditorPick) {
    attribs.push(ATTRIB.EDITORS_PICK)
  }

  if (venueItem.isChapterPick) {
    attribs.push(ATTRIB.CHAPTER_PICK)
  }

  if (forceFeatured) {
    attribs.push(ATTRIB.FEATURED)
  }

  return attribs
}

/**
 * Check premium flags
 */
export function isPremium(venueListItem: TVenueItem): boolean {
  return (
    venueListItem.isEditorPick ||
    venueListItem.isChapterPick
  )
}

/**
 * Validate venue list item
 */
export function isListItemValid(venueListItem: TVenueItem): venueListItem is TValidVenueItem {
  return isLocationCoordsValid(venueListItem)
}

/**
 * Validate item
 */
export function isValid(venue: TVenue): venue is TValidVenue {
  return isLocationCoordsValid(venue.location)
}

/**
 * Validate coords
 */
function isLocationCoordsValid(coords: { lat: number | null, lon: number | null }): boolean {
  return (
    // Filter out invalid entries (any null)
    (coords.lat !== null || coords.lon !== null) &&
    // Filter out assumed invalid entries (both zeros)
    (coords.lat !== 0 && coords.lon !== 0)
  )
}

/**
 * Generic error page, used by other components
 */
const ErrorPage: preact.FunctionComponent<{
  title: string,
  description: string,
}> = ({
  title,
  description,
}) => (
  <section className="iyp-page iyp-page--content">
    <h1 className="iyp-h1">
      {title}
    </h1>
    <p>
      {description}
    </p>
  </section>
)

export default ErrorPage

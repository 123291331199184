/**
 * ES2022
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Error#parameters
 */
export type TErrorOptions = {
  /** Original error cause */
  cause?: Error
}

/**
 * Abstract error
 */
export default class AbstractError extends Error {
  /**
   * @inheritdoc
   */
  constructor(
    message?: string,
    options?: TErrorOptions,
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(message, options)

    // V8 only, doesn't prepend cause
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, new.target)
    }

    /**
     * Set the prototype explicitly on when compilation target is ES5.
     * @see https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
     */
    Object.setPrototypeOf(this, AbstractError.prototype)
  }
}

AbstractError.prototype.name = 'AbstractError'

/**
 * @link https://data-api-v1-staging.inyourpocket.com/event/25958
 */

import type { TApiEventDetailsResponse } from '../responses/event'
import type { IVenueListItem } from '../../models/venue.interfaces'

import {
  getUrl,
  getAttribs,
  isListItemValid,
  isPremium,
} from './utils/venue'

type IEvent = {
  venues: IVenueListItem[]
}

export default function transformEvent (payload: TApiEventDetailsResponse, showFeaturedOnly = false): IEvent {
  // Note: aSociated instead of aSSociated
  let associatedVenues = payload.data.asociatedVenues

  if (showFeaturedOnly) {
    associatedVenues = associatedVenues.filter(isPremium)
  }

  return {
    venues: associatedVenues
      .filter(isListItemValid)
      .map(associatedVenue => ({
        id: associatedVenue.venue_id,
        name: associatedVenue.name,
        location: [
          associatedVenue.lat,
          associatedVenue.lon,
        ],
        attribs: getAttribs(associatedVenue),
        url: getUrl(associatedVenue),
      }))
  }
}

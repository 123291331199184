import { memo } from 'preact/compat'

import type { IService } from '../../../models/venue.interfaces'

import '../../../icons.css'
import './Services.css'

const Services: preact.FunctionComponent<{
  value: IService[]
}> = ({
  value
}) =>
  <div className="venue-extra">
    <h3 className="iyp-venue-extra__heading">
      {'Amenities'}
    </h3>
    <ul className="iyp-venue-extra__content iyp-list-flat">
      {value.map((service, index) =>
        <li key={index}>
          <span
            className={`iyp-service i-${service.letter}`}
            title={service.name}
          ></span>
        </li>
      )}
    </ul>
  </div>

export default memo(Services)

/**
 * @see https://sergiodxa.com/articles/render-as-you-fetch/
 */

import { Suspense } from 'preact/compat'

import type { IVenueListItem } from '../../models/venue.interfaces'
import { premiumAttribs } from '../../models/venue.constants'
import useVenue from '../../data/use-venue'

import Spinner from '../Spinner/Spinner'
import Error from '../Error/Error'
import ErrorBoundary from '../../Utils/ErrorBoundary'

import VenueShareButton, { canShare } from '../VenueShareButton/VenueShareButton'
import Description from '../Description/Description'
import VenueGallery from '../VenueGallery/VenueGallery'

import Services from './Extras/Services'
import Website from './Extras/Website'
import OpeningHours from './Extras/OpeningHours'
import OpeningHoursText from './Extras/OpeningHoursText'
import PriceRange from './Extras/PriceRange'
import SocialLinks from './Extras/SocialLinks'
import Directions from './Extras/Directions'

import './VenueDetails.css'

/**
 * Venue placeholder view
 */
const VenuePlaceholder: preact.FunctionComponent<{
  venueListItem: IVenueListItem
}> = ({
  venueListItem,
}) => {
  return <Spinner />
}

/**
 * Venue details view
 */
const VenueDetails: preact.FunctionComponent<{
  venueListItem: IVenueListItem
}> = ({
  venueListItem,
}) => {
  // Note: When using suspense, it's non-trivial to handle recoverable connection errors.
  // While keeping SWR retry mechanism
  const {
    venue,
    error,
    mutate,
  } = useVenue(venueListItem.id, false, { suspense: false })

  // When suspense is off
  if (error) {
    return <Error error={error} inline retryFn={mutate} />
  }

  // When suspense is off
  if (!venue) {
    return <Spinner />
  }

  // Detect premium type
  const isPremium = venueListItem.attribs.some(attr => premiumAttribs.includes(attr))

  return (
    <div>
      <ul className="iyp-venue-contact-details">
        {/** Address */}
        <li>
          <span className="iyp-icon iyp-icon--before iyp-color--purple i-location"></span>
          {venue.address}
        </li>

        {/** Phone */}
        {isPremium && venue.phoneNumber &&
          <li>
            <span className="iyp-icon iyp-icon--before iyp-color--purple i-phone"></span>
            <a href={`tel:${venue.phoneNumber.replace(/\s+/g, '')}`}>
              {venue.phoneNumber}
            </a>
          </li>
        }

        {/** Directions */}
        <li>
          <span className="iyp-icon iyp-icon--before iyp-color--purple i-navigate"></span>
          <Directions location={venue.location} />
        </li>

        {/** Share */}
        {canShare &&
          <li>
            <VenueShareButton venue={venue} />
          </li>
        }
      </ul>

      {/** Premium venue */}
      {isPremium &&
        <>
          {/** Gallery */}
          {venue.photos && venue.photos.length !== 0 &&
            <VenueGallery photos={venue.photos} />
          }
          {/** Lead/ Description */}
          <Description
            lead={venue.lead}
            text={venue.description}
          />

          <div className="iyp-venue-details__extras">
            {/** Amenities/ Services */}
            {venue.services.length !== 0 &&
              <Services value={venue.services} />
            }

            {/** Website */}
            {venue.website &&
              <Website value={venue.website} />
            }

            {/** Opening hours */}
            {venue.open &&
              <OpeningHours value={venue.open} />
            }

            {/** Opening hours pretty format */}
            {venue.openHoursText &&
              <OpeningHoursText value={venue.openHoursText} />
            }

            {/** Price range */}
            {venue.priceRange !== null &&
              <PriceRange value={venue.priceRange} />
            }

            {/** Social links */}
            {venue.socials.length !== 0 &&
              <SocialLinks value={venue.socials} />
            }
          </div>
        </>
      }
    </div>
  )
}

export default VenueDetails

/**
 * Venue suspense wrapper
 */
/*
const VenueSuspenseWrapper: preact.FunctionComponent<{
  venueListItem: IVenueListItem
}> = ({
  venueListItem,
}) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={
        <VenuePlaceholder venueListItem={venueListItem} />
      }>
        <VenueDetails venueListItem={venueListItem} />
      </Suspense>
    </ErrorBoundary>
  )
}

export default VenueSuspenseWrapper
*/

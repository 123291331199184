import useVenueList from '../../data/use-venue-list'
import { hasShowFeaturedOnly } from '../../Utils/url'

import Map from '../../Components/Map/Map'
import Spinner from '../../Components/Spinner/Spinner'
import Error from '../../Components/Error/Error'
import PageHeading from '../../Components/PageHeading/PageHeading'

/**
 * Chapter
 * Note: ATM Chapter API endpoint doesn't return anything helpful
 *       Need to request city info, however no way to find out city id
 *
 * Parameters:
 * - show-all-venues: number - Show all venues (by default only featured)
 */
const ChapterPage: preact.FunctionComponent<{
  params: {
    chapterId: string
  }
}> = ({
  params: {
    chapterId
  }
}) => {
  const { venues, error: transferError, retry } = useVenueList({
    chapterId: Number.parseInt(chapterId, 10),
    featuredOnly: hasShowFeaturedOnly(window.location.search),
  })

  return (
    <section className="iyp-page iyp-page--map">
      <PageHeading
        label="Chapter"
        features={['Venues for current chapter (blue)']}
      />

      {transferError
        ? <Error error={transferError} retryFn={retry} />
        : venues
          ? <Map venuesList={venues} />
          : <Spinner />
      }

    </section>
  )
}

export default ChapterPage

/**
 * Transformer for venuesList path
 */
import type { IVenueListItem } from '../../models/venue.interfaces'
import type { TApiVenueListResponse } from '../responses/venue-list'

import {
  getUrl,
  getAttribs,
  isListItemValid,
} from './utils/venue'

export default function transformList (
  payload: TApiVenueListResponse,
  forceFeatured = false
): IVenueListItem[] {
  return payload.venueList
    .filter(isListItemValid)
    .map(venueListItem => ({
      id: venueListItem.venue_id,
      name: venueListItem.name,
      location: [ venueListItem.lat, venueListItem.lon ],
      attribs: getAttribs(venueListItem, forceFeatured),
      url: getUrl(venueListItem),
    }))
}

import { memo } from 'preact/compat'
import { useState } from 'preact/hooks'
import DOMPurify from 'dompurify'

import Button from '../Button/Button'

import './Description.css'

const sanitizeConfig = {
  ALLOWED_TAGS: ['a', 'br'],
  ALLOWED_ATTR: ['href'],
  ALLOWED_URI_REGEXP: RegExp('^https:\\/\\/www\\.inyourpocket\\.com\\/'),
}

/**
 * Venue description
 * Warning: Links open is same window
 */
const Description: preact.FunctionComponent<{
  lead: string
  text: string
}> = ({
  lead,
  text,
}) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false)

  return (
    <div className="iyp-description iyp-margin--bottom">
      <div
        className="iyp-description__content"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(isOpen ? text : lead, sanitizeConfig)
        }}
      ></div>
      <Button
        text={isOpen ? 'Read less' : 'Read more'}
        className="iyp-description__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`iyp-button__icon iyp-icon i-arrow-${isOpen ? 'left' : 'right'}`}></span>
      </Button>
    </div>
  )
}

export default memo(Description)

/**
 * Button to open direction to point
 * @see https://developers.google.com/maps/documentation/urls/get-started
 *
 * The `https://www.google.com/maps?daddr=` is  probably deprecated
 */
const Directions: preact.FunctionComponent<{
  location: [number, number]
}> = ({
  location
}) => {
  // Build search params
  const queryParams = new URLSearchParams({
    api: '1',
    destination: location.toString(),
    dir_action: 'navigate',
  })

  /**
   * Handle click: Open in new window
   * Desktop: Restrict window features so user has to close it when done
   * Mobile: New windows opens up, but while Google Maps app whens it's closed instantly
   * Unfortunately google maps prevents from being opened inside iframe
   */
  const handleClick = (event: JSX.TargetedMouseEvent<HTMLAnchorElement>): void => {
    if (event.target instanceof HTMLAnchorElement) {
      event.preventDefault()
      window.open(event.target.href, 'directions', 'popup=yes,noopener')
    }
  }

  return (
    <a
      href={`https://www.google.com/maps/dir/?${queryParams}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {'Get Directions'}
    </a>
  )
}

export default Directions

import { memo } from 'preact/compat'

const PriceRange: preact.FunctionComponent<{
  value: number
}> = ({
  value
}) =>
  <div className="iyp-venue-extra">
    <h3 className="iyp-venue-extra__heading">
      {'Price range'}
    </h3>
    <p className="iyp-venue-extra__content">
      {value} / 5
    </p>
  </div>

export default memo(PriceRange)

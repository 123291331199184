/**
 * App routes
 * Similar to API endpoints, but without chapter
 */
const routes = {
  // Start/ Home page
  home: '/',
  // Chapter page (Venues for current category)
  chapter: '/chapters/:chapterId',
  // Category page
  category: '/categories/:categoryId',
  // Featured article (Associated venues, Venues for current category)
  feature: '/features/:featureId',
  // Venue (Current venue, Associated venues)
  venue: '/venues/:venueId',
  // Event (Associated venues)
  event: '/events/:eventId',
} as const

export default routes

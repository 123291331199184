/**
 * Import meta env context to not use 'import.meta.env' inside components
 */
import { createContext } from 'preact'
import { useContext } from 'preact/hooks'

/**
 * Env object
 */
export const EnvContext = createContext<ImportMetaEnv>({
  BASE_URL: '/',
  MODE: 'production',
  DEV: false,
  PROD: true,
  SSR: false,
  VITE_API_URL: '',
  VITE_IYP_ORIGIN: '',
})

/**
 * Env hook
 */
export function useEnv() {
  return useContext(EnvContext)
}

/**
 * Env context provider
 */
export const EnvProvider: preact.FunctionComponent<{
  env: ImportMetaEnv
}> = ({
  env = import.meta.env,
  children,
}) => {
  return (
    <EnvContext.Provider value={ env }>
      {children}
    </EnvContext.Provider>
  )
}

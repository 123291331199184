import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import type { TApiVenueListResponse } from './responses/venue-list'
import TransferError from '../Utils/Error/TransferError'
import { createRetry } from './utils'
import transform from './transformers/venue-list'

export default function useVenueList(
  params: {
    chapterId?:    number|number[],
    categoryId?:   number|number[],
    districtId?:   number|number[],
    featureId?:    number|number[],
    /** If definied will show picks only. Set value to 1 if enabled */
    featuredOnly?: boolean,
    /** Number of items to be returned. Default 100 */
    limit?:        number,
    /** Ofset for pagination */
    offset?:       number,
  } = {},
  /** Add featured flag to all venues */
  forceFeatured = false,
  configuration?: SWRConfiguration<TApiVenueListResponse>
) {
  const urlSearchParams = new URLSearchParams()

  // Append params, each as single value
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined && value !== false) {
      urlSearchParams.set(
        formatUspKey(key),
        formatUspValue(value)
      )
    }
  }

  const {
    data: venues,
    error,
    mutate,
  } = useSWR<TApiVenueListResponse, TransferError>(`venueList?${urlSearchParams}`, configuration)

  return {
    venues: venues ? transform(venues, forceFeatured) : undefined,
    error,
    mutate,
    retry: createRetry(mutate),
  }
}

/**
 * Format URL search param key
 * Convert camelCase to snake_case
 */
function formatUspKey (key: string): string {
  return key.replace(/[A-Z]/g, letter =>
    `_${letter.toLowerCase()}`
  )
}

/**
 * Format URL search param value
 */
function formatUspValue (value: number | number[] | boolean): string {
  if (typeof value === 'boolean') {
    value = value ? 1 : 0
  }

  return value.toString()
}
